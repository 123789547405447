import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import i18n from "i18next";
import { Swiper, SwiperSlide } from 'swiper/react';

import Template from '../templates/template';
import FlipSlide from '../components/flipbook/flipslide';

// Import Swiper styles
import 'swiper/css';

const FlipBook = inject("stores") (
    observer (
        class FlipBook extends Component {
            constructor(props) {
                super(props);
                this.props = props;

                this.storeUi = this.props.stores.storeUi;   
                this.storeContent = this.props.stores.storeContent;   

                this.state = {
                    flippages: []
                }
            }

            componentDidMount = async() => {
                let pages;
                this.currentLang = this.props.stores.storeUi.app_interface.lang;

                if (this.storeContent.content_flippages.length === 0) {
                    console.log('SbC flipslides:')
                    pages = await this.storeContent.getContentPages('flippages', 100);

                    this.setState({
                        flippages: pages
                    })
                }
            }

            componentDidUpdate = async() => {
                let pages;
                if (this.storeContent.content_flippages.length === 0) {
                    console.log('SbC flipslides:')
                    pages = await this.storeContent.getContentPages('flippages', 100);
                }
            }

            render() {
                i18n.changeLanguage(this.storeUi.app_interface.lang);
                
                return (
                    <Template>
                        <div className="wrapper--page">
                            <Swiper
                                spaceBetween={50}
                                slidesPerView={1}
                            >
                                {this.storeContent.content_flippages && this.storeContent.content_flippages.map((slide) => (
                                // {this.state.flippages && this.state.flippages.map((slide) => (
                                    <SwiperSlide>
                                        <FlipSlide
                                            slide = {slide}
                                        />
                                    </SwiperSlide>
                                ))}
                            </Swiper>
                        </div>
                    </Template>
                );
            }
        }
    )
);

export default FlipBook;