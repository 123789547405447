import React, { Component } from 'react';
import { inject, observer } from "mobx-react";
import {withRouter} from '../../utilities/withRouter';
import { Link } from 'react-router-dom';
import { Redirect } from 'react-router-dom';
import i18n from "i18next";

const NavigationCorporate = inject("stores") (
    observer (
        class NavigationCorporate extends Component {

            constructor(props) {
                super(props);
                this.storeUi = this.props.stores.storeUi;
                this.storeNavigation = this.props.stores.storeNavigation;
            }

            componentDidMount = async () => {
                window.addEventListener('scroll', this.isSticky);
                i18n.changeLanguage(this.storeUi.app_interface.lang);
            }

            componentDidUpdate = (prevProps) => {
            }

            gotoPage = (url) => {
                this.props.history.push(url);
            }

            //const margin_bottom = this.storeUi.app_interface.height_footer;

            //<div style={{marginBottom: margin_bottom}}>

            render() {
                i18n.changeLanguage(this.storeUi.app_interface.lang);

                return (
                    <div className="wrapper--navigation-corporate">
                        {/* <ul className="navigation-utilities__list">
                            {this.storeNavigation.utilities.map((utility) => (
                                <li>{i18n.t("navigation." + utility.i18n)}</li>
                            ))}
                        </ul> */}
                        <ul>
                            <li>About</li>
                            <li>Get in touch</li>
                            <li>Newsletter</li>
                        </ul>
                    </div>
                )
            }
        }
    )
)

export default withRouter(NavigationCorporate);

