import React, { Component } from 'react';
import { inject, observer } from "mobx-react";
import { Routes, Route } from 'react-router-dom';
import {withRouter} from './utilities/withRouter';

import UnderConstruction from './pages/under-construction';
import SettingLanguage from './pages/setting-language';
import Start from './pages/start';
import Flipbook from './pages/flipbook';
import PageArticles from './pages/articles';
import PageCulture from './pages/culture';
import PageCollection from './pages/collection';
import PageCollaborations from './pages/collaborations';
import PageVision from './pages/vision';
import PageShelterlab from './pages/shelterlab';
import PageFoundation from './pages/foundation';
import ContentPage from './pages/content';

const AppRoutes = inject("stores") (
    observer (
        class AppRoutes extends Component {

            constructor(props) {
                super(props);
                this.props = props;
                this.storeUi = this.props.stores.storeUi; 
                this.storeContent = this.props.stores.storeContent;
                this.storeNavigation = this.props.stores.storeNavigation; 
            }

            render() {
                return (
                    <React.Fragment>
                        <Routes>
                            <Route 
                                path='/' 
                                element={
                                    <UnderConstruction 
                                        lang = {this.storeUi.lang} 
                                    />
                                }
                            />
                            <Route 
                                path='/start' 
                                element={
                                    <Start 
                                        lang = {this.storeUi.lang} 
                                    />
                                }
                            />
                            <Route 
                                path='/culture' 
                                element={<PageCulture 
                                    lang = {this.storeUi.lang} 
                                />
                                }
                            />
                            <Route 
                                path='/articles' 
                                element={<PageArticles 
                                    lang = {this.storeUi.lang} 
                                />
                                }
                            />
                            <Route 
                                path='/collection'
                                element={<PageCollection 
                                    lang = {this.storeUi.lang} 
                                />
                                }
                            />
                             <Route 
                                path='/collectie'
                                element={<PageCollection 
                                    lang = {this.storeUi.lang} 
                                />
                                }
                            />
                            <Route 
                                path='/flipbook' 
                                element={<Flipbook 
                                    lang = {this.storeUi.lang} 
                                />
                                }
                            />
                            <Route 
                                path='/collaborations' 
                                element={<PageCollaborations 
                                    lang = {this.storeUi.lang} 
                                />
                                }
                            />
                            <Route 
                                path='/vision' 
                                element={<PageVision  
                                    lang = {this.storeUi.lang} 
                                />
                                }
                            />
                            <Route 
                                path='/shelterlab' 
                                element={<PageShelterlab 
                                    lang = {this.storeUi.lang} 
                                />
                                }
                            />
                            <Route 
                                path='/foundation' 
                                element={<PageFoundation 
                                    lang = {this.storeUi.lang} 
                                />
                                }
                            />
                            <Route 
                                path='/set-language' 
                                element={<SettingLanguage 
                                    lang = {this.storeUi.lang} 
                                />
                                }
                            />
                            <Route 
                                path = "/content/:title" 
                                element = {
                                    <ContentPage
                                        type = {this.storeContent.currentPageType}
                                        idx = {this.storeNavigation.idCurrentPage}
                                        lang = {this.storeUi.lang} 
                                    />
                                } 
                            />
                        </Routes>
                    </React.Fragment>
                );
            }
        }
    )
)

export default withRouter(AppRoutes);