import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import i18n from "i18next";

const FlipSlide = inject("stores") (
    observer (
        class FlipSlide extends Component {
            constructor(props) {
                super(props);
                this.props = props;
                this.storeUi = this.props.stores.storeUi;   
                this.storeContent = this.props.stores.storeContent;  
                
                this.state = {
                    slide: {}
                }
            }

            componentDidMount = async() => {
                let featuredImage;
                this.currentLang = this.props.stores.storeUi.app_interface.lang;

                if (this.props.slide.better_featured_image !== null && this.props.slide.better_featured_image.media_details.sizes) {
                    featuredImage = this.props.slide.better_featured_image.media_details.sizes.medium.source_url;
                }
                this.setState({
                    slide: this.props.slide,
                    featuredImage: featuredImage
                })
                console.log('SbC flipslide:', this.props.slide)
            }

            componentDidUpdate = async(prevProps) => {
                let featuredImage;

                if (prevProps.slide !== this.props.slide) {
                    if (this.props.slide.better_featured_image !== null && this.props.slide.better_featured_image.media_details.sizes) {
                        featuredImage = this.props.slide.better_featured_image.media_details.sizes.medium.source_url;
                    }
                    this.setState({
                        slide: this.props.slide,
                        featuredImage: featuredImage
                    })
                }
            }

            render() {
                i18n.changeLanguage(this.storeUi.app_interface.lang);
                
                return (
                    <div 
                        className="wrapper--slide"
                        style= {{backgroundImage:`url(${this.state.featuredImage})`}}
                    >
                        
                        <div 
                            className="wrapper--slide__column"
                        >
                            {this.state.slide && this.state.slide.title &&
                                <div
                                    className = 'slide__header'
                                    dangerouslySetInnerHTML = {{ __html: this.state.slide.title.rendered}}
                                />
                            }
                            {this.state.slide && this.state.slide.content &&
                                <div
                                    className = 'slide__content-block'
                                    dangerouslySetInnerHTML = {{ __html: this.state.slide.content.rendered}}
                                />
                            }
                        </div>
                        <div 
                            className="wrapper--slide__column"
                        >
                        </div>
                        <div 
                            className="wrapper--slide__column"
                        >
                        </div>
                    </div>
                );
            }
        }
    )
);

export default FlipSlide;